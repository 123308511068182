import React, { Fragment, Component } from 'react';
import { Link, graphql } from 'gatsby';
import format from 'date-fns/format';
import { colors } from '../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import WhitePaperFocus from '../../components/WhitePapersGrid/WhitePaperFocus';

class WhitePaperPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false,
      allServices: {
        edges: [
          {
            node: {
              wordpress_id: 20,
              slug: 'crm-loyalty-marketing',
              name: 'CRM and Loyalty Marketing'
            }
          },
          {
            node: {
              wordpress_id: 21,
              slug: 'national-local-media',
              name: 'National and Local Media'
            }
          },
          {
            node: {
              wordpress_id: 19,
              slug: 'through-channel-marketing-automation',
              name: 'Through-Channel Marketing Automation'
            }
          }
        ]
      }
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/v1/preview/`;
      fetch(`${BASE_URL}${id}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const item = this.state.previewPayload;
    if (item) {
      item.title = item.post_title;
      item.content = item.post_content;
      item.services = [20];
      item.better_featured_image = {
        source_url:
          'https://via.placeholder.com/1500?text=Featured+Image+Not+Previewable'
      };
    }
    return item ? (
      <WhitePaperFocus
        item={item}
        allServices={this.state.allServices}
        isPreviewMode={this.state.isPreviewMode}
      />
    ) : null;
  }
}

export default WhitePaperPreview;
